@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/select/lib/css/blueprint-select.css';
@import '~@blueprintjs/datetime/lib/css/blueprint-datetime.css';

/* needed these to fix height issue for tables */
html {
  height: 100%;
}

#root {
  height: 100%;
}

/* reset file used to set commonalities between all browsers */

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
textarea {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: 'Roboto', sans-serif;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  /* add height to fix issue with tables */
  height: 100%;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-spacing: 0;
}

/* to make name in sidebar full width */
.sidebar-popover .bp3-popover-target {
  width: 100%;
}

/* to get action button in table to change  */
/* for the action buttons that have the popover menu only */
.table-action-menu .bp3-popover-open svg {
  fill: #fff;
}

/* to get action button in table to change  */
/* for the action buttons that have the popover menu only */
.table-action-menu .bp3-popover-open button {
  background: #470d82 !important;
}

.bp3-select-popover {
  width: 271px;
}

/* dialog header */
.bp3-dialog-header {
  padding: 25px 0 25px 50px;
  box-shadow: none;
  border-bottom: 1px solid #caced6;
}

.bp3-dialog-header .bp3-heading {
  line-height: 22px;
}

.custom-dialog-header .bp3-dialog-header {
  padding: 25px 0 25px 30px;
  border-bottom: none;
}

/* dialog header title */
.bp3-dialog-header h4 {
  font-size: 1.25rem;
}

/* DIALOG BOX STYLING */
/* this will need media queries for width if responsiveness is needed in the future */
.bp3-dialog {
  background: #fff;
  padding: 0;
  width: 700px;
}

/* dialog box for warning user */
.small-dialog {
  width: 550px;
}

.medium-dialog {
  width: 950px;
}

.large-dialog {
  width: 1150px;
}

.border-less-header .bp3-dialog-header {
  border-bottom: none;
}

/* style for tooltip */
.bp3-tooltip .bp3-popover-content {
  width: fit-content;
  background: #470d82;
}

/* style for tooltip */
.bp3-tooltip .bp3-popover-arrow-fill {
  fill: #470d82;
}

/*style for date picker*/
.custom-date-picker .bp3-input-group:last-child {
  padding-left: 50px;
}

.custom-date-picker .DayPicker:first-child {
  margin-right: 25px;
}

/*Radio pagination*/
.radio-pagination {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  margin-bottom: 5px;
}

.radio-pagination .bp3-radio {
  margin-right: 0px;
}

.radio-pagination .bp3-control input:checked ~ .bp3-control-indicator {
  background: #470d82;
}

.radio-pagination .bp3-control input:checked ~ .bp3-control-indicator::before {
  background-image: none;
}

.radio-pagination .bp3-control input:focus ~ .bp3-control-indicator {
  outline: none;
}

/*monetary input*/
.currency-input:before {
  content: '$';
}

/*Percentage input*/
.percentage-input:after {
  content: '%';
}

/*Style non ideal state(blueprint core component) of widget*/
.widget .non-ideal-state {
  padding: 20px;
}
.widget .bp3-non-ideal-state {
  justify-content: initial;
}
.widget .non-ideal-state svg {
  width: 40px;
  height: 40px;
}
.widget .non-ideal-state .bp3-heading {
  font-size: 1.125rem;
}
.maturing-widget .non-ideal-state {
  height: 60%;
  padding: 0px;
}
.maturing-widget .bp3-non-ideal-state {
  height: auto;
}
/*End of Style non ideal state*/

/*Global settings search bar-small. Important to take precedence over inline style*/
.global-setting .bp3-input {
  width: 225px !important;
}

.radio-group .bp3-label {
  font-size: 1.125rem;
  color: #4f5467;
  font-weight: 700;
}

/* override default placement of close button */
.bp3-multi-select {
  align-items: flex-start !important;
}

/* hide delete button class for data ingestion templates */
.hide-button {
  display: none !important;
}
